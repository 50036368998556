/* eslint-disable react/button-has-type */
import clsx from 'clsx'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'
import { BasicStyledComponent } from 'shared-definitions/types'
import styles from './BasicButton.module.css'

interface BasicButtonProps extends BasicStyledComponent {
  onClick?: () => void
  dataAttrs?: Record<`data-${string}`, string>
  type?: 'button' | 'submit'
}

const BasicButton: React.FC<BasicButtonProps> = ({
  type,
  dataAttrs = {},
  className,
  onClick,
  children,
}) => {
  const { buttonStyle } = UIVariantContext.useContainer()
  return (
    <button
      {...dataAttrs}
      type={type ?? 'button'}
      className={clsx(className, styles.container, {
        [styles.fill]: buttonStyle === 'fill',
        [styles.outline]: buttonStyle === 'outline',
      })}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default BasicButton
