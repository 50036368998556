/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import Linked from 'shared-components/service/Linked'
import { BasicStyledComponent, DSDataAttrs, DSPostShortWithAuthor } from 'shared-definitions/types'
import clsx from 'clsx'
import styles from './PostRow.module.css'
import React from 'react'
import AppImage from 'shared-components/ui/AppImage'
import { BreakPointSize } from 'shared-code/responsive'
import PostedByContainer from 'shared-components/posts/PostedByContainer'
import stylesCommon from './PostRow.PostRowTiny.module.css'
import dynamic from 'next/dynamic'
import { UIVariantContext } from 'shared-components/contexts/UIVariantContext'

const ScoreLine = dynamic(() => import('shared-components/ui/Score'))
const ScoreRing = dynamic(() => import('shared-components/ui/ScoreRing'))

interface PostRowProps extends BasicStyledComponent, DSDataAttrs {
  post: DSPostShortWithAuthor
  large?: boolean
  onClick?: () => void
}

const PostRow: React.VFC<PostRowProps> = React.memo(
  ({ onClick, className, post, large, ...dataProps }) => {
    const { postPreviewScore } = UIVariantContext.useContainer()
    const imageBps: BreakPointSize[] = large
      ? [
          { bp: 'xl', val: 370 },
          { bp: 'md', val: 270 },
        ]
      : [{ bp: 'md', val: 270 }]

    return (
      <Linked
        onClick={onClick}
        className={clsx(styles.container, className, { [styles.large]: large })}
        {...dataProps}
        {...post.pLink}
      >
        <div>
          <div className={styles.title}>{post.title}</div>
          <PostedByContainer
            post={post}
            className={stylesCommon.postedBy}
            dateClassName={stylesCommon.postedByDate}
          />
        </div>
        <div className={styles.right}>
          {post.image ? (
            <AppImage
              image={post.image}
              layout="raw"
              className={styles.image}
              sizes={[...imageBps, 90]}
            />
          ) : null}
          {post.score && postPreviewScore === 'line' ? (
            <ScoreLine className={styles.scoreLine} score={post.score} />
          ) : null}
          {post.score && postPreviewScore === 'ring' ? (
            <ScoreRing className={styles.scoreRing} score={post.score} />
          ) : null}
          {post.badge ? (
            <AppImage
              image={post.badge}
              imageClassName={styles.badgeImage}
              className={clsx(styles.badge, { [styles.badgeRing]: postPreviewScore === 'ring' })}
              sizes={[{ bp: 'md', val: 50 }, 40]}
              layout="raw"
            />
          ) : null}
        </div>
      </Linked>
    )
  }
)

export default PostRow
