/* eslint-disable react/display-name */
import styles from './PostRowTiny.module.css'
import PostedByContainer from 'shared-components/posts/PostedByContainer'
import AppImage from 'shared-components/ui/AppImage'
import clsx from 'clsx'
import { BasicStyledComponent, DSPostShortWithAuthor } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import stylesCommon from './PostRow.PostRowTiny.module.css'
import React from 'react'

interface PostRowTinyProps extends BasicStyledComponent {
  post: DSPostShortWithAuthor
  mobileView?: boolean
}

const PostRowTiny: React.VFC<PostRowTinyProps> = React.memo(({ post, className, mobileView }) => (
  <Linked
    className={clsx(styles.container, className, {
      [styles.mobileView]: mobileView,
    })}
    {...post.pLink}
  >
    <div>
      <div className={styles.title}>{post.title}</div>
      <PostedByContainer
        post={post}
        className={clsx(stylesCommon.postedBy, { [stylesCommon.mobileView]: mobileView })}
        dateClassName={stylesCommon.postedByDate}
      />
    </div>
    {post.image ? (
      <AppImage
        image={post.image}
        layout="raw"
        className={styles.image}
        sizes={mobileView ? 90 : [{ bp: 'lg', val: 143 }, 90]}
      />
    ) : null}
  </Linked>
))

export default PostRowTiny
